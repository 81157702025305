"use client"

import Cloud from '@/components/cloud'

export default function Page() {
  return (
    <div className="flex flex-col gap-4 p-4">
      <Cloud />
    </div>
  );
}
