import '@/styles/cloud.css';

import { 
  Cctv, 
  Brain, 
  Bluetooth, 
  Monitor, 
  Stethoscope, 
  Forklift, 
  Truck, 
  Smartphone, 
  Laptop,
  ThermometerSun,
  Lamp,
  LampDesk,
  KeyRound,
} from "lucide-react"

export default function Cloud({}) {
  const size = 20;
  const strokeWidth = 1.25;

  return (
    <div className="flex items-start justify-center min-w-[360px] min-h-[240px] sm:min-w-[480px] ciot-cloud">
      <div className="ciot-cloud-icon1 dark:fill-slate-200">
        <Cctv size={size} strokeWidth={strokeWidth} />
      </div>
      <div className="ciot-cloud-icon2 dark:fill-slate-200">
        <Truck size={size} strokeWidth={strokeWidth} />
      </div>
      <div className="ciot-cloud-icon5 dark:fill-slate-200">
        <LampDesk size={size} strokeWidth={strokeWidth} />
      </div>
      <div className="ciot-cloud-icon3 dark:fill-slate-200">
        <Bluetooth size={size} strokeWidth={strokeWidth} />
      </div>
      <div className="ciot-cloud-icon4 dark:fill-slate-200">
        <Monitor size={size} strokeWidth={strokeWidth} />
      </div>
      <div className="ciot-cloud-icon7 dark:fill-slate-200">
        <Stethoscope size={size} strokeWidth={strokeWidth} />
      </div>
      <div className="ciot-cloud-icon5 dark:fill-slate-200">
        <Forklift size={size} strokeWidth={strokeWidth} />
      </div>
      <div className="ciot-cloud-icon6 dark:fill-slate-200">
        <Brain size={size} strokeWidth={strokeWidth} />
      </div>
      <div className="ciot-cloud-icon7 dark:fill-slate-200">
        <Smartphone size={size} strokeWidth={strokeWidth} />
      </div>
      <div className="ciot-cloud-icon8 dark:fill-slate-200">
        <Laptop size={size} strokeWidth={strokeWidth} />
      </div>
      <div className="ciot-cloud-icon9 dark:fill-slate-200">
        <ThermometerSun size={size} strokeWidth={strokeWidth} />
      </div>
      <div className="ciot-cloud-icon4 dark:fill-slate-200">
        <Lamp size={size} strokeWidth={strokeWidth} />
      </div>
      <div className="ciot-cloud-icon2 dark:fill-slate-200">
        <KeyRound size={size} strokeWidth={strokeWidth} />
      </div>
    </div>
  );
}